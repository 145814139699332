import {hoverTooltip} from "@codemirror/view"
import {syntaxTree} from "@codemirror/language"


function cleanUrl(url: string): string {
  if (url.startsWith("<")) {
    url = url.slice(1)
  }
  if (url.endsWith(">")) {
    url = url.slice(0, -1)
  }
  return url
}

export const urlPlugin = hoverTooltip((view, pos, side) => {
  let node = syntaxTree(view.state).resolve(pos)
  if (node.type.name === "URL") {
    return {
      pos: pos,
      end: node.to,
      above: true,
      create(view) {
        let link = document.createElement("a")
        link.textContent = "Follow link"
        link.target = "_blank"
        link.href = cleanUrl(view.state.doc.sliceString(node.from, node.to))

        let dom = document.createElement("div")
        dom.className = "cm-follow-link"
        dom.append(link)
        return {dom}
      }
    }      
  }
  return null
})
