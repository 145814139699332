import { MarkdownConfig } from '@lezer/markdown'

import { customTags } from './highlight'
import { space } from './utils'


export const MentionHighlighter: MarkdownConfig = {
  defineNodes: [{name: "Mention", style: customTags.mention}],
  parseInline: [{
    name: "Mention",
    parse(cx, next, pos) {
      let match: RegExpExecArray | null
      if (next !== 64 /* '@' */ || !space(cx.char(pos - 1)) || !(match = /^[\w_:-]+/.exec(cx.slice(pos + 1, cx.end)))) return -1
      return cx.addElement(cx.elt("Mention", pos, pos + 1 + match[0].length))
    },
    before: "Escape"
  }]
}
