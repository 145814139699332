import { EditorView, Decoration, ViewUpdate, ViewPlugin, DecorationSet } from '@codemirror/view';
import { syntaxTree } from "@codemirror/language"
import { RangeSetBuilder } from '@codemirror/state'


const DAY = 24 * 60 * 60 * 1000


function getDays(text: string): number | undefined {
  const date = new Date(text)
  if (isNaN(date.getTime())) return undefined  // invalid date
  
  const now = new Date()
  const diffToDate = date.getTime() - now.getTime()

  // Return days left/overdue
  return Math.round(diffToDate / DAY) + 1
}

function getClass(days: number | undefined): string {
  if (days === undefined)
    return ""
  else if (days <= -5)
    return "cm-date-5d-exp"
  else if (days <= -1)
    return "cm-date-1d-exp"
  else if (days === 0)
    return "cm-date-today"
  else if (days === 1)
    return "cm-date-tomorrow"

  return ""
}

function getTitle(days: number | undefined): string {
  if (days === undefined) 
    return ""
  
  if (days < -1)
    return `Overdue ${-days} days!`
  else if (days === -1)
    return `Overdue ${-days} day!`
  else if (days === 0)
    return "Today!"
  else if (days === 1)
    return "Tomorrow"
  else 
    return `In ${days} days`
}

function dates(view: EditorView) {
  let builder = new RangeSetBuilder<Decoration>()
  for (let { from, to } of view.visibleRanges) {
    syntaxTree(view.state).iterate({
      from,
      to,
      enter: ({type, from, to}) => {
        if (type.name === "Date") {
          const text = view.state.doc.sliceString(from + 2, to) // Skip // symbols
          const days = getDays(text)
          const addClass = getClass(days)
          builder.add(from, to, Decoration.mark({
            class: "cm-date " + addClass,
            attributes: {
              title: getTitle(days),
            }
          }))
        }
      }
    });
  }
  return builder.finish();
}

export const datePlugin = ViewPlugin.fromClass(
  class {
    decorations: DecorationSet;

    constructor(view: EditorView) {
      this.decorations = dates(view);
    }

    update(update: ViewUpdate) {
      if (update.docChanged || update.viewportChanged) {
        this.decorations = dates(update.view);
      }
    }
  },
  {
    decorations: (v) => v.decorations
  }
);
