import ReactModal from 'react-modal';
import styled from 'styled-components';

const Dialog = styled.div`
  width: 40rem;

  background-color: #fff;
  border-radius: 6px;

  color: #000;
`;
const DialogHeader = styled.div`
  padding: 0.5rem 1rem;

  font-size: 1.5rem;
`;
const DialogBody = styled.div`
  padding: 0.5rem 1rem;
`;
const DialogFooter = styled.div`
  display: flex;

  padding: 0.5rem 1rem;

  justify-content: flex-end;
`;
type DialogButtonProps = {
  color?: "default" | "danger" | "info";
};
const DialogButton = styled.button<DialogButtonProps> `
  min-width: 5em;

  margin: 0 0 0 1rem;
  padding: 0.5rem;

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 13pt;

  border: none;
  border-radius: 6px;

  color: #fff;
  background-color: ${(props) => {
    const color = props.color || "default";
    switch (color) {
      case "default":
        return "#00d1b2";

      case "danger":
        return "#f14668";

      case "info":
        return "#3298dc";
    }
  }};

  cursor: pointer;

  &:hover {
    background-color: ${(props) => {
    const color = props.color || "default";
    switch (color) {
      case "default":
        return "#00c4a7";

      case "danger":
        return "#f03a5f";

      case "info":
        return "#2793da";
    }
  }};
  }
`;
type ConfirmDialogProps = {
  isOpen: boolean;
  message: string;
  handler: (result: boolean) => void;
};
export function ConfirmDialog({ isOpen, message, handler }: ConfirmDialogProps) {
  const style: ReactModal.Styles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)'
    },
    content: {
      position: 'absolute',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      overflow: 'auto',
      WebkitOverflowScrolling: 'touch',
      outline: 'none',
      backgroundColor: 'rgba(0, 0, 0, 0)',
      border: 'none',
    }
  };

  return (
    <ReactModal isOpen={isOpen} onRequestClose={() => handler(false)} style={style}>
      <Dialog>
        <DialogHeader>Delete</DialogHeader>
        <DialogBody>{message}</DialogBody>
        <DialogFooter>
          <DialogButton color='danger' onClick={() => handler(true)}>Delete</DialogButton>
          <DialogButton color='info' onClick={() => handler(false)}>Cancel</DialogButton>
        </DialogFooter>
      </Dialog>
    </ReactModal>
  );
}
