import { Storage, ProviderInfo } from "./interface";
import DropboxStorage from "./dropbox";
import DummyStorage from "./dummy";

export function getStorage(): Storage | null {
    const provider = window.localStorage.getItem("auth.provider");
    const jsonData = window.localStorage.getItem("auth.data");
    let data = null;
    if (jsonData) {
        data = JSON.parse(jsonData);
    }
    switch (provider) {
        case "dropbox":
            return new DropboxStorage(data);
        
        case "dummy":
            return new DummyStorage(data);
    }

    return null;
}

export async function getAuthProviders(): Promise<ProviderInfo[]> {
    return [
        // TODO: Implement local storage
        await DummyStorage.getProviderInfo(),
        await DropboxStorage.getProviderInfo(),
    ]
}

export function setAuthData(provider: string, data: object) {
    window.localStorage.setItem("auth.provider", provider);
    window.localStorage.setItem("auth.data", JSON.stringify(data));
}

export function clearAuthData() {
    window.localStorage.removeItem("auth.provider");
    window.localStorage.removeItem("auth.data");
}
