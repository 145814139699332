import styled from 'styled-components';

export const NoFileContainer = styled.div`
  display: flex;

  height: 100%;

  justify-content: center;
  align-items: center;

  background-color: #eee;
`;
export const NoFileBanner = styled.div`
`;
