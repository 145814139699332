import { EditorView } from '@codemirror/view';


export const theme = EditorView.theme({
    ".cm-scroller": {
      overflow: "auto",
    },
    ".cm-checkbox": {
      cursor: "pointer",
      transition: "background-color .5s ease",
    },
    ".cm-checkbox:hover": {
      backgroundColor: "#eeee",
    },
    ".cm-tag,.cm-mention": {
      cursor: "pointer",
      transition: "opacity .5s ease",
    },
    ".cm-tag:hover,.cm-mention:hover": {
      opacity: ".5"
    },
    ".cm-code-block": {
      backgroundColor: "#ddd8"
    },
    ".cm-code-inline": {
      backgroundColor: "#ddd8"
    },
    ".cm-code-block .cm-code-inline": {
      backgroundColor: "transparent"
    },
    ".cm-date": {
      // backgroundColor: "",
      margin: "-1px",
      border: "1px solid #000", 
      borderRadius: "3px"
    },
    ".cm-date-5d-exp": {
      backgroundColor: "#f008",
    },
    ".cm-date-1d-exp": {
      backgroundColor: "#f888",
    },
    ".cm-date-today": {
      backgroundColor: "#ff08",
    },
    ".cm-date-tomorrow": {
      backgroundColor: "#ffd8",
    },

    ".cm-follow-link": {
      padding: "0.5em 1em",
    },
  });
