import styled from 'styled-components';

import { IconButton } from './IconButton';


export const FilesBar = styled.aside`
  display: flex;
  flex-direction: column;

  flex: 0 0 25%;
  min-width: 15rem;
  max-width: 25rem;

  padding: 10px;

  overflow-y: auto;

  background-color: #eee;
`;
export const FilesButtonBar = styled.div`
  margin: 0;
  padding: 3px 0;
`;
export const FilesWrapper = styled.div`
  flex-grow: 1;
  overflow-y: auto;
`;
export const FilesList = styled.ul`
  list-style-type: none;

  padding-inline-start: 0;

  & > & {
    padding-inline-start: 1em;
  }
`;
interface FileItemProps {
  readonly active: boolean;
}
export const FileItem = styled.li<FileItemProps> `
  display: flex;

  padding: 5px;

  background-color: ${(props) => props.active ? '#ddd' : 'none'};

  &:hover {
    background-color: #ddd;
  }
`;
export const FileNewItem = styled.li`
  display: flex;

  padding: 5px;

  background-color: none;
`;
export const FileIcon = styled.span``;
export const FileLink = styled.a`
  flex-grow: 1;

  margin-left: 5px;

  text-decoration: none;
  color: black;
`;
export const FolderName = styled.span`
  flex-grow: 1;

  margin-left: 5px;

  text-decoration: none;
  color: black;
`;
export const FileInput = styled.input`
  flex-grow: 1;

  margin-left: 5px;
`;
export const FileInputIconButton = styled(IconButton)`
  margin-left: 3px;
`;
export const FileIconButton = styled(IconButton)`
  margin-left: 3px;
  display: none;

  ${FileItem}:hover & {
    display: inline;
  }
`;
