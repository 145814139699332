import { useCodeMirror } from '@uiw/react-codemirror';
import { ChangeEventHandler, KeyboardEventHandler, MouseEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import BeatLoader from "react-spinners/BeatLoader";

import { buildString } from '../buildString';
import { AppContainer } from '../components/AppContainer';
import { ConfirmDialog } from '../components/ConfirmDialog';
import { EditorBody, EditorContainer } from '../components/EditorContainer';
import { FileIcon, FileIconButton, FileInput, FileInputIconButton, FileItem, FileLink, FileNewItem, FilesBar, FilesButtonBar, FilesList, FilesWrapper, FolderName } from '../components/FileItem';
import { Footer } from '../components/Footer';
import { ErrorBanner, Header, HeaderButton, HeaderLastSave, HeaderTitle, HeaderUser } from '../components/Header';
import { Icon } from '../components/Icon';
import { IconButton } from '../components/IconButton';
import { MainContainer } from '../components/MainContainer';
import { NoFileBanner, NoFileContainer } from '../components/NoFileContainer';
import { Spinner } from '../components/Spinner';
import { extensions, sortItems } from '../editor';
import { useTitle } from '../hooks/useTitle';
import { AuthError } from '../storage/error';
import { FileInfo, SaveCallback, Storage } from "../storage/interface";
import { clearAuthData, getStorage } from '../storage/storage';

interface File extends FileInfo {
  name: string;
  children: File[];
}
function makeTree(infos: FileInfo[]): File {
  let root: File = { type: "folder", path: "", name: "", children: [] };
  let files = new Map<string, File>([['', root]]);

  infos.sort((a, b) => {
    if (a.type !== b.type) {
      return (a.type === "folder") ? -1 : 1;
    }

    return a.path.localeCompare(b.path);
  });

  for (const info of infos) {
    const name = info.path.split("/").at(-1)!;
    const parent = info.path.split("/").slice(0, -1).join("/");
    const file: File = { ...info, name, children: [] };

    files.set(info.path, file);
    files.get(parent)?.children?.push(file);
  }

  return root;
}
const isMac = (navigator.appVersion.indexOf('Mac') !== -1);
const ctrlKey = isMac ? "Mod" : "Ctrl";
const FETCH_DELAY = 60000; // ms

export function Editor() {
  window.console.log("Reinit App");

  const [content, setContent] = useState<string | undefined>(undefined);

  const [user, setUser] = useState<string | null>(null);
  const [errorMsg, setErrorMsg] = useState<string>("");

  const params = useParams();
  const filename = params['*'];

  const filenameRef = useRef<string | undefined>("");
  filenameRef.current = filename;

  const navigate = useNavigate();

  useTitle(filename);

  const storage = useRef<Storage | null>(null);
  const updateHandler = useCallback<SaveCallback>((path: string, err?: any) => {
    window.console.log("Save happened", path, err);
    if (err) {
      setErrorMsg(`Could not save ${path}: ${err}`);
    } else {
      if (path === filenameRef.current) {
        const d = new Date();
        setSaveState("saved");
        setLastUpdate(d.toLocaleTimeString());
        setErrorMsg("");
      }
    }
  }, []);

  const fetchUser = async () => {
    if (storage.current) {
      console.log("Fetching user...");
      try {
        setUser(await storage.current.getUser());
      } catch (e) {
        if (e instanceof AuthError) {
          if (filename)
            window.sessionStorage.setItem("auth.current_filename", filename);
          const authUrl = await storage.current.getAuthUrl();
          window.location.href = authUrl;
        } else {
          setErrorMsg(`Error: ${e}`);
          window.console.error("An error occured", e);
        }
      }
    } else {
      navigate('/auth');
    }
  };

  useEffect(() => {
    storage.current = getStorage();
    if (storage.current) {
      storage.current.saveCallback = updateHandler;
    }

    fetchUser();
  }, []);

  useEffect(() => {
    const unloadHandler = (e: BeforeUnloadEvent) => {
      if (storage.current && storage.current.flush()) {
        e.preventDefault();
        return e.returnValue = "There are unsaved changes";
      }
    };
    window.addEventListener("beforeunload", unloadHandler);

    return () => {
      window.removeEventListener("beforeunload", unloadHandler);
    };
  }, [storage]);

  const lastVisible = useRef<number>(0);
  useEffect(() => {
    const visibilityHandler = () => {
      if (window.document.visibilityState === 'visible') {
        if (Date.now() - lastVisible.current >= FETCH_DELAY) {
          fetchUser();
        }
      } else {
        lastVisible.current = Date.now();
      }
    };
    window.addEventListener("visibilitychange", visibilityHandler);

    return () => {
      window.removeEventListener("visibilitychange", visibilityHandler);
    };
  }, [storage, filename]);

  const [root, setRoot] = useState<File | undefined>(undefined);
  const [saveState, setSaveState] = useState("");
  const [lastUpdate, setLastUpdate] = useState<string>("");

  const [isListingFiles, setListingFiles] = useState(false);

  const listFiles = async () => {
    if (storage.current) {
      try {
        setListingFiles(true);
        const filesList = await storage.current.list();
        setRoot(makeTree(filesList));
      } finally {
        setListingFiles(false);
      }
    }
  };
  useEffect(() => {
    window.console.log("List files");
    listFiles();
  }, []);

  const [isLoadingContent, setLoadingContent] = useState(false);

  useEffect(() => {
    const fetchFile = async () => {
      if (storage.current && filename) {
        setSaveState("");
        setLastUpdate("");
        setLoadingContent(true);
        try {
          const fileContent = await storage.current.getFileContent(filename);
          setErrorMsg("");
          setContent(fileContent);
        } finally {
          setLoadingContent(false);
        }
      }
    };
    window.console.log("Filename change", filename);
    fetchFile();
  }, [filename]);

  const changeHandler = (value: string) => {
    // Skip if change happens as a result of file change.
    if (value === content)
      return;

    if (storage.current && filename) {
      setSaveState("saving");
      storage.current.saveFileContent(filename, value);
    }
  };

  const [newFilePath, setNewFilePath] = useState<string | undefined>(undefined);
  const [newFolderPath, setNewFolderPath] = useState<string | undefined>(undefined);
  const [newItemName, setNewItemName] = useState("");
  const [invalidFilenameMsg, setInvalidFilenameMsg] = useState("");

  const genNewFileHandler = (parent: string) => () => {
    setNewFilePath(parent);
    setInvalidFilenameMsg("File name cannot be empty");
  };

  const genCreateFileHandler = (parent: string) =>
    async () => {
      if (storage.current && newItemName) {
        const path = (parent ? parent + "/" : "") + newItemName;
        await storage.current.create(path);
        await listFiles();
        setNewItemName("");
        setNewFilePath(undefined);
        navigate(`/notes/${path}`);
      }
    };

  const genNewFolderHandler = (parent: string) => () => {
    setNewFolderPath(parent);
    setInvalidFilenameMsg("Folder name cannot be empty");
  };

  const genCreateFolderHandler = (parent: string) =>
    async () => {
      if (storage.current && newItemName) {
        const path = (parent ? parent + "/" : "") + newItemName;
        await storage.current.createFolder(path);
        await listFiles();
        setNewItemName("");
        setNewFolderPath(undefined);
      }
    };

  const genFileHandler = (path: string) => (e: MouseEvent<HTMLAnchorElement>) => {
    // setFilename(path)
    navigate(`/notes/${path}`);
    e.preventDefault();
  };

  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [deleteFilename, setDeleteFilename] = useState("");

  const genDeleteFileHandler = (path: string) =>
    async () => {
      if (!storage.current)
        return;

      setDeleteFilename(path);
      setShowDeleteConfirm(true);
    };

  const deleteHandler = async (response: boolean) => {
    if (!storage.current)
      return;
    if (response) {
      if (filename === deleteFilename) {
        setContent(undefined);
        navigate(`/notes`);
      }

      await storage.current.delete(deleteFilename);
      await listFiles();
    }
    setShowDeleteConfirm(false);
  };

  const editor = useRef<HTMLDivElement | null>(null);
  const { setContainer, view } = useCodeMirror({
    container: editor.current,
    height: "100%",
    extensions,
    value: content,
    onChange: changeHandler
  });

  useEffect(() => {
    if (editor.current) {
      setContainer(editor.current);
    }
  }, [content]);

  const sortHandler = () => {
    if (view)
      sortItems(view);
  };

  const logOutHandler = async () => {
    if (storage.current) {
      await storage.current.logout();
    }
    clearAuthData();
    navigate("/");
  };

  const newItemCreation = (newFolderPath !== undefined || newFilePath !== undefined);

  const renderFile = (info: File) => <FileItem key={info.path} active={(info.path === filename)}>
    <FileIcon><Icon icon={info.type === "file" ? 'fa-file' : 'fa-folder'} /></FileIcon>
    <FileLink href={`/notes/${info.path}`} onClick={genFileHandler(info.path)}>{info.name}</FileLink>
    <FileIconButton title='Remove file' disabled={newItemCreation} onClick={genDeleteFileHandler(info.path)}><Icon icon='fa-trash' /></FileIconButton>
  </FileItem>;

  const renderFolder = (info: File) => <>
    <FileItem key={info.path} active={(info.path === filename)}>
      <FileIcon><Icon icon='fa-folder' /></FileIcon>
      <FolderName>{info.name}</FolderName>
      <FileIconButton title='New File...' disabled={newItemCreation} onClick={genNewFileHandler(info.path)}><Icon icon='fa-file-circle-plus' /></FileIconButton>
      <FileIconButton title='New Folder...' disabled={newItemCreation} onClick={genNewFolderHandler(info.path)}><Icon icon='fa-folder-plus' /></FileIconButton>
      <FileIconButton title='Remove folder' disabled={newItemCreation} onClick={genDeleteFileHandler(info.path)}><Icon icon='fa-trash' /></FileIconButton>
    </FileItem>
    {renderItems(info)}
  </>;

  const renderNewFileInput = (info: File) => {
    const createHandler = genCreateFileHandler(info.path);
    const cancelHandler = () => { setNewFilePath(undefined); setNewItemName(""); };
    const keyDownHandler: KeyboardEventHandler<HTMLInputElement> = (e) => {
      if (invalidFilenameMsg)
        return;
      if (e.key === "Enter")
        createHandler();
    };
    const changeHandler: ChangeEventHandler<HTMLInputElement> = (e) => {
      if (!e.target.value) {
        setInvalidFilenameMsg("File name cannot be empty");
      } else if (!/.+\.(md|txt)$/.test(e.target.value)) {
        setInvalidFilenameMsg("File name has to have .md or .txt extension");
      } else {
        setInvalidFilenameMsg("");
      }
      setNewItemName(e.target.value);
    };

    return (
      <FileNewItem>
        <FileIcon><Icon icon='fa-file' /></FileIcon>
        <FileInput type="text" autoFocus value={newItemName} onChange={changeHandler} onKeyDown={keyDownHandler} />
        <FileInputIconButton disabled={!!invalidFilenameMsg} title={invalidFilenameMsg || "Create file"} onClick={createHandler}><Icon icon='fa-check' /></FileInputIconButton>
        <FileInputIconButton title="Cancel" onClick={cancelHandler}><Icon icon='fa-xmark' /></FileInputIconButton>
      </FileNewItem>);
  };

  const renderNewFolderInput = (info: File) => {
    const createHandler = genCreateFolderHandler(info.path);
    const cancelHandler = () => { setNewFolderPath(undefined); setNewItemName(""); };
    const keyDownHandler: KeyboardEventHandler<HTMLInputElement> = (e) => {
      if (invalidFilenameMsg)
        return;
      if (e.key === "Enter")
        createHandler();
    };
    const changeHandler: ChangeEventHandler<HTMLInputElement> = (e) => {
      if (!e.target.value) {
        setInvalidFilenameMsg("Folder name cannot be empty");
      } else {
        setInvalidFilenameMsg("");
      }
      setNewItemName(e.target.value);
    };

    return (
      <FileNewItem>
        <FileIcon><Icon icon='fa-folder' /></FileIcon>
        <FileInput type="text" autoFocus value={newItemName} onChange={changeHandler} onKeyDown={keyDownHandler} />
        <FileInputIconButton disabled={!!invalidFilenameMsg} title={invalidFilenameMsg || "Create folder"} onClick={createHandler}><Icon icon='fa-check' /></FileInputIconButton>
        <FileInputIconButton title="Cancel" onClick={cancelHandler}><Icon icon='fa-xmark' /></FileInputIconButton>
      </FileNewItem>);
  };

  const renderItem = (file: File) => (file.type === "file" ? renderFile(file) : renderFolder(file));

  const renderItems = (file: File) => <FilesList>
    {file.children?.map(renderItem)}
    {file.path === newFolderPath ? renderNewFolderInput(file) : null}
    {file.path === newFilePath ? renderNewFileInput(file) : null}
  </FilesList>;

  return (
    <AppContainer>
      <Header>
        <img src={process.env.PUBLIC_URL + "/logo192.png"} alt="MD Notes Logo" width={48} height={48} />
        <HeaderTitle>Notes</HeaderTitle>
        {errorMsg ? <HeaderLastSave><ErrorBanner>Error: {errorMsg}</ErrorBanner></HeaderLastSave> : null}
        {saveState === "saving" ? <HeaderLastSave>Saving <BeatLoader size={5} color="#fff" cssOverride={{ display: "inline" }} /></HeaderLastSave> : null}
        {lastUpdate && (saveState === "saved") ? <HeaderLastSave title={lastUpdate}>Saved <Icon icon='fa-circle-check' /></HeaderLastSave> : null}
        <HeaderButton onClick={sortHandler} title={`Sort tags (${ctrlKey}-Q)`}><Icon icon='fa-hashtag' /> Sort tags</HeaderButton>
        {user ? <><HeaderUser><Icon icon='fa-user' /> {user}</HeaderUser><HeaderButton onClick={logOutHandler} title="Log out"><Icon icon='fa-right-from-bracket' /></HeaderButton></> : null}
      </Header>
      <MainContainer>
        <ConfirmDialog isOpen={showDeleteConfirm} message={`Are you sure you want to delete ${deleteFilename}?`} handler={deleteHandler} />
        <FilesBar>
          {isListingFiles ? <Spinner /> :
            <>
              <FilesButtonBar>
                <IconButton title='New File...' disabled={newItemCreation} onClick={genNewFileHandler("")}><Icon icon='fa-file-circle-plus' /></IconButton>
                <IconButton title='New Folder...' disabled={newItemCreation} onClick={genNewFolderHandler("")}><Icon icon='fa-folder-plus' /></IconButton>
              </FilesButtonBar>
              <FilesWrapper>
                {(root !== undefined) ? renderItems(root) : null}
              </FilesWrapper>
            </>}
        </FilesBar>
        <EditorContainer>
          {(isLoadingContent) ? <Spinner /> : (content !== undefined) ? <EditorBody ref={editor}></EditorBody> : <NoFileContainer><NoFileBanner>No file selected</NoFileBanner></NoFileContainer>}
        </EditorContainer>
      </MainContainer>
      <Footer title={buildString()}>
        &copy; Serhii Shvorob 🇺🇦, 2022-2023
      </Footer>
    </AppContainer>
  );
}
