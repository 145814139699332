import '@fortawesome/fontawesome-free/css/all.min.css';
import { Navigate, Route, Routes } from 'react-router-dom';
import './App.css';

import { Auth } from './pages/Auth';
import { AuthDropbox } from './pages/AuthDropbox';
import { AuthDummy } from './pages/AuthDummy';
import { Editor } from './pages/Editor';


function App() {
  return (
    <Routes>
      <Route path='/' element={<Navigate to='/notes' />} />
      <Route path='/notes/*' element={<Editor />} />
      
      <Route path='/auth' element={<Auth />} />
      <Route path='/auth/dropbox' element={<AuthDropbox />} />
      <Route path='/auth/dummy' element={<AuthDummy />} />
    </Routes>
  )
}

export default App;
