import styled from 'styled-components';

export const Header = styled.header`
  flex: 0 0 3em;

  padding: 0 1em;

  color: #fff;
  background-color: #00d1b2;

  display: flex;
  align-items: center;

  -webkit-app-region: drag;
`;
export const HeaderTitle = styled.div`
  flex: 1 1 auto;
`;
export const HeaderButton = styled.button`
  flex: 0 0 auto;
  
  margin: 0 5px;
  padding: 6px;

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 13pt;

  border-style: solid;
  border-color: #00ffd9;
  border-width: 1px;
  border-radius: 6px;

  color: #fff;
  background-color: #00d1b2;

  cursor: pointer;

  -webkit-app-region: no-drag;

  &:hover {
    background-color: #00c4a7;
  }
`;
export const HeaderLastSave = styled.div`
  flex: 0 0 auto;
  margin: 0 5px;
`;
export const ErrorBanner = styled.div`
  background-color: #e00;
  border: 3px solid #f00;
`;
export const HeaderUser = styled.div`
  flex: 0 0 auto;
  margin: 0 5px;

`;
