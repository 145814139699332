import { EditorView, Decoration, ViewUpdate, ViewPlugin, DecorationSet } from '@codemirror/view';
import { syntaxTree } from "@codemirror/language"
import { RangeSetBuilder } from '@codemirror/state'
import { startCompletion } from '@codemirror/autocomplete'
import { getSearchQuery, searchPanelOpen } from "@codemirror/search";
import { str } from "crc-32";


function getTagColor(tag: string): string {
  const hash = str(tag) >>> 0

  const h = (hash & 0xffff) % 360
  const s = ((hash >>> 16) & 0xff) % 50 + 50
  const l = ((hash >>> 24) & 0xff) % 30 + 60

  return `hsla(${h}, ${s}%, ${l}%, 80%)`
}

function generateDecorations(view: EditorView, from: number, to: number, className: string, 
                             background: boolean, builder: RangeSetBuilder<Decoration>) {
  const text = view.state.doc.sliceString(from + 1, to) // Skip # or @ symbols
  const color = getTagColor(text)
  let style = `margin: -1px; border: 1px solid ${color}; border-radius: 3px`
  if (background) {
    style = `background-color: ${color}; ` + style
  }
  builder.add(from, to, Decoration.mark({
    class: className, 
    attributes: {
      style: style
    }
  }))
}

function tagsMentions(view: EditorView, background: boolean) {
  let builder = new RangeSetBuilder<Decoration>()
  for (let { from, to } of view.visibleRanges) {
    syntaxTree(view.state).iterate({
      from,
      to,
      enter: ({type, from, to}) => {
        if (type.name === "Tag") {
          generateDecorations(view, from, to, "cm-tag", background, builder)
        } else if (type.name === "Mention") {
          generateDecorations(view, from, to, "cm-mention", background, builder)
        }
      }
    });
  }
  return builder.finish();
}

export const tagMentionPlugin = ViewPlugin.fromClass(
  class {
    decorations: DecorationSet
    lastSearchPanelState: boolean = false

    constructor(view: EditorView) {
      this.decorations = tagsMentions(view, true)
    }

    update(update: ViewUpdate) {
      let searchPanelState = searchPanelOpen(update.state)
      if (update.docChanged || update.viewportChanged || this.lastSearchPanelState !== searchPanelState) {
        this.decorations = tagsMentions(update.view, !searchPanelState)
        this.lastSearchPanelState = searchPanelState
      }
    }
  },
  {
    decorations: (v) => v.decorations,

    eventHandlers: {
      mousedown: (e, view) => {
        if (e.button !== 0) return // react only on left button click

        let target = e.target as HTMLElement;
        if (target.closest(".cm-tag, .cm-mention")) {
          const pos = view.posAtCoords({x: e.clientX, y: e.clientY})
          if (pos !== null) {
            view.dispatch(view.state.update({selection: {anchor: pos}, scrollIntoView: true, userEvent: "select"}))
            return startCompletion(view)
          }
        }
      }
    }
  }
);
