import { EditorView, keymap, Decoration, ViewUpdate, ViewPlugin, DecorationSet } from '@codemirror/view';
import { syntaxTree } from "@codemirror/language"
import {ChangeSpec, Range} from '@codemirror/state'


const checkboxDeco = Decoration.mark({class: "cm-checkbox"})

function checkboxes(view: EditorView) {
  let marks: Range<Decoration>[] = [];
  for (let { from, to } of view.visibleRanges) {
    syntaxTree(view.state).iterate({
      from,
      to,
      enter: ({type, to}) => {
        if (type.name === "TaskMarker") {
          marks.push(checkboxDeco.range(to - 3, to));
        }
      }
    });
  }
  return Decoration.set(marks);
}

function toggleCheck(view: EditorView, pos: number) {
  const mark = view.state.doc.sliceString(pos + 1, pos + 2);
  let change: ChangeSpec;
  if (mark === "x") {
    change = { from: pos + 1, to: pos + 2, insert: " " };
  } else if (mark === " ") {
    change = { from: pos + 1, to: pos + 2, insert: "x" };
  } else {
    return false;
  }
  view.dispatch({ changes: change });
  return true;
}

export const checkboxPlugin = ViewPlugin.fromClass(
  class {
    decorations: DecorationSet;

    constructor(view: EditorView) {
      this.decorations = checkboxes(view);
    }

    update(update: ViewUpdate) {
      if (update.docChanged || update.viewportChanged) {
        this.decorations = checkboxes(update.view);
      }
    }
  },
  {
    decorations: (v) => v.decorations,

    eventHandlers: {
      mousedown: (e, view) => {
        let target = e.target as HTMLElement;
        if (target.closest(".cm-checkbox")) {
          return toggleCheck(view, view.posAtDOM(target));
        }
      }
    }
  }
);
