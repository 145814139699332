import styled from 'styled-components';

export const ProvidersContainer = styled.div`
  display: flex;
  flex-direction: column;

  flex: 1;

  height: 100%;

  align-items: center;

  background-color: #eee;
`;
export const ProvidersList = styled.div`
  display: flex;
  flex-direction: column;

  flex: 1;

  justify-content: center;
  align-items: stretch;

  background-color: #eee;
`;
export const ProvidersHeader = styled.div`
  margin: 1em;

  font-size: 16pt;

  text-align: center;
`;
export const ProviderItem = styled.a`
  display: block;

  padding: 1em 1em;
  margin: 1em;

  border: solid 1px #000;
  border-radius: 6px;

  text-decoration: none;
  text-align: center;
  color: black;

  &:hover {
    background-color: #ddd;
  }
`;
