import styled from 'styled-components';

export const IconButton = styled.button`
  border: none;

  background-color: transparent;

  &:enabled:hover {
    background-color: #ccc;
  }
`;
