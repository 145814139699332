import { Dropbox, DropboxAuth, DropboxResponseError } from "dropbox";

import { Storage, FileInfo, ProviderInfo, SaveCallback } from "./interface";
import { CacheLayer } from "./cache";
import { AuthError } from "./error";


const CLIENT_ID = process.env.REACT_APP_DROPBOX_CLIENT_ID;
const REDIRECT_URI = `${window.location.origin}/auth/dropbox`

let authUrl: string
async function getAuthUrl(): Promise<string> {
    if (!authUrl) {
        const auth = new DropboxAuth({clientId: CLIENT_ID});
        authUrl = await auth.getAuthenticationUrl(REDIRECT_URI, undefined, 'code', undefined, undefined, undefined, true) as string;
        window.localStorage.setItem("auth.dropbox.code_verifier", auth.getCodeVerifier());
    }
    return authUrl
}

export default class DropboxStorage extends CacheLayer implements Storage {

    private client: Dropbox
    saveCallback?: SaveCallback

    static async getProviderInfo(): Promise<ProviderInfo> {
        const authUrl = await getAuthUrl()
        return {
            provider: "dropbox",
            title: "Dropbox",
            icon: "fa-brands fa-dropbox",
            link: authUrl,
        }
    }

    static async getAuthData(): Promise<Object> {
        const auth = new DropboxAuth({clientId: CLIENT_ID})
        const code = new URLSearchParams(window.location.search).get("code")
        const codeVerifier = window.localStorage.getItem('auth.dropbox.code_verifier')

        if (code === null || codeVerifier === null)
            throw "Bad codnitions"
        auth.setCodeVerifier(codeVerifier)

        return {
            access_token: (await (auth.getAccessTokenFromCode(REDIRECT_URI, code)) as any).result.access_token
        } 
    }

    constructor(authData: object) {
        super();
        this.client = new Dropbox({accessToken: (authData as any).access_token})
    }

    async getAuthUrl(): Promise<string> {
        return await getAuthUrl()
    }

    async getUser(): Promise<string> {
        try {
            const response = await this.client.usersGetCurrentAccount()
            return response.result.name.display_name
        } catch (e) {
            if ((e instanceof DropboxResponseError) && (e.status === 401)) {
                throw new AuthError();
            }
            throw e;
        }
    }

    async logout(): Promise<void> {
        this.client.authTokenRevoke()
    }

    async list(): Promise<FileInfo[]> {
        const response = await this.client.filesListFolder({path: '', recursive: true})
        return response.result.entries
            .filter((item) => (item.path_display !== null)) // Item can be unmounted and have no path. Skip it.
            .map((item) => ({path: item.path_display!.slice(1), "type": ( item[".tag"] === "folder" ? "folder" : "file")}))
    }

    async create(path: string): Promise<void> {
        await this.client.filesUpload({
            path: `/${path}`, 
            contents: "" 
        })
    }

    async createFolder(path: string): Promise<void> {
        await this.client.filesCreateFolderV2({ path: `/${path}` })
    }

    async delete(path: string): Promise<void> {
        await this.client.filesDeleteV2({path: `/${path}`})
    }

    async _get(path: string): Promise<string> {
        const response = await this.client.filesDownload({path: `/${path}`})

        const readPromise = new Promise<string>((resolve, reject) => {
            const reader = new FileReader();
            reader.addEventListener('loadend', () => resolve(reader.result as string));
            reader.addEventListener('error', () => reject("Read error"));
            reader.readAsText((response.result as any).fileBlob);
        })

        return await readPromise
    }

    async _save(path: string, content: string): Promise<void> {
        await this.client.filesUpload({
            path: `/${path}`, 
            contents: content,
            mode: {
                '.tag': 'overwrite',
              },
        })
    }
}
