import { MarkdownConfig } from '@lezer/markdown'

import { customTags } from './highlight'
import { space } from './utils'


export const TagHighlighter: MarkdownConfig = {
  defineNodes: [{name: "Tag", style: customTags.tag}],
  parseInline: [{
    name: "Tag",
    parse(cx, next, pos) {
      let match: RegExpExecArray | null
      if (next !== 35 /* '#' */ || !space(cx.char(pos - 1)) || !(match = /^[\w_:-]+/.exec(cx.slice(pos + 1, cx.end)))) return -1
      return cx.addElement(cx.elt("Tag", pos, pos + 1 + match[0].length))
    }
  }]
}
