import BeatLoader from "react-spinners/BeatLoader";
import styled from 'styled-components';

const SpinnerContainer = styled.div`
  display: flex;
  height: 100%;

  justify-content: center;
  align-items: center;

  background-color: #eee;
`;
export function Spinner() {
  return (
    <SpinnerContainer>
      <BeatLoader color='#aaa' />
    </SpinnerContainer>
  );
}
