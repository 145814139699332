import styled from 'styled-components';

export const MainContainer = styled.main`
  flex: 1;

  display: flex;
  flex-direction: row;

  overflow: auto;
`;
