import { SaveCallback } from "./interface";

const SAVE_INTERVAL = 10_000 // ms = 10 s


export abstract class CacheLayer {
    private queue = new Map<string, string>()
    private timerId: number | undefined

    saveCallback?: SaveCallback

    abstract _get(path: string): Promise<string>
    abstract _save(path: string, content: string): Promise<void>

    saveHandler() {
        this.queue.forEach((content: string, path: string) => {
            this._save(path, content).then(() => {
                if (this.saveCallback)
                    this.saveCallback(path)
            }, (reason: any) => {
                if (this.saveCallback)
                    this.saveCallback(path, reason)
            })
        })
        this.queue.clear()
        this.timerId = undefined
    }

    async getFileContent(path: string): Promise<string> {
        const content = this.queue.get(path)

        if (content !== undefined) {
            return content
        }

        return this._get(path)
    }

    async saveFileContent(path: string, content: string): Promise<void> {
        this.queue.set(path, content)

        if (!this.timerId) {
            this.timerId = window.setTimeout(() => this.saveHandler(), SAVE_INTERVAL)
        }
    }
    
    flush(): boolean {
        if (this.timerId) {
            this.saveHandler()
            return true
        }
        return false
    }
}