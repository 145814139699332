import { EditorView, keymap } from '@codemirror/view';
import { ChangeSpec } from '@codemirror/state'


type Area = {
  matchOr: string[]
  line: number
  pos: number
  insertPos: number
}

export function sortItems({state, dispatch}: EditorView) {

  if (state.readOnly) return false
  
  let areas: Area[] = []

  function lineArea(pos: number): Area | null {
    let area: Area | null = null
    for (const item of areas) {
      if (item.pos < pos) {
        if (item.pos > (area ? area.insertPos : 0)) {
          area = item
        }
      }
    }
    return area
  }

  function findArea(tags: string[]): Area | null {
    for (const item of areas) {
      for (const tag of tags) {
        if (item.matchOr.includes(tag)) {
          return item
        }
      }
    }
    return null
  }

  for (let pos = 0, line = 1, iter = state.doc.iter();;) {
    if (iter.done) break
    
    if (!iter.lineBreak) {
      const match = iter.value.match(/%area\[([^\]]*)\]/)
      if (match) {
        let area: Area = {matchOr: [], line: 1, pos: 0, insertPos: 0}
        const [, paramsStr] = match
        for (const param of paramsStr.split(";")) {
          const [name, valueStr] = param.split("=", 2)
          switch (name) {
            case "match_or":
              area.matchOr = valueStr.split(",")
              break
            case "checked":
              if (valueStr.toLowerCase() === "true") {
                area.matchOr = ["#DONE"]
              } else if (valueStr.toLowerCase() === "false") {
                area.matchOr = ["#TODO"]
              }
              break
            case "line":
              area.line = parseInt(valueStr, 10)
              break
          }
        }

        if (area.matchOr.length) {
          area.pos = pos

          // FIXME: Check boundaries
          const insertLine = state.doc.line(line + area.line)
          area.insertPos = insertLine.from

          areas.push(area)
        }
      } else {
        if (/^\s*#+\s/.test(iter.value)) {
          const tags = iter.value.match(/\B#[\w_:-]+/g)
          if (tags?.length) {
            let area: Area = {matchOr: tags, line: 2, pos: 0, insertPos: 0}
            area.pos = pos
  
            // FIXME: Check boundaries
            const insertLine = state.doc.line(line + area.line)
            area.insertPos = insertLine.from
  
            areas.push(area)
          }
        }
      }
    } else {
      line += 1
    }

    pos += iter.value.length
    iter.next()
  }

  let changes: ChangeSpec[] = []
  for (let pos = 0, iter = state.doc.iter();;) {
    if (iter.done) break
    
    if (!iter.lineBreak) {
      const filteredText = iter.value.replaceAll(/%area\[([^\]]*)\]/g, "")
      const match = filteredText.match(/\B#[\w_:-]+/g)
      let tags: string[] = []
      // Skip headers
      if (/^\s*#+\s/.test(iter.value)) {
        // pass
      } else if (match) {
        tags = match
      } else {
        const match = iter.value.match(/^\s*[*+-]\s+\[([ xX])\]/)
        if (match) {
          const [, mark] = match
          if (mark.toLowerCase() === "x") {
            tags = ["#DONE"]
          } else {
            tags = ["#TODO"]
          }
        }
      }

      if (tags.length) {
        const currArea = lineArea(pos)

        // Apply only if tag belongs to some area.
        if (currArea) {
          const area = findArea(tags)

          if (area && currArea !== area) {
            changes.push(
              {from: pos, to: pos + iter.value.length + 1},
              {from: area.insertPos, insert: iter.value + state.lineBreak}
            )
          }
        }
      }
    }

    pos += iter.value.length
    iter.next()
  }

  if (!changes.length) return false
  dispatch(state.update({changes, scrollIntoView: true, userEvent: "sort"}))
  return true
}

export const sortItemsKeymap = keymap.of([{
  key: "Mod-q",
  preventDefault: true,
  run: sortItems
}])
