import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useTitle } from '../hooks/useTitle';
import DropboxStorage from '../storage/dropbox';
import { setAuthData } from '../storage/storage';

export function AuthDropbox() {
  const navigate = useNavigate();
  useTitle("Dropbox");

  useEffect(() => {
    async function auth() {
      const data = await DropboxStorage.getAuthData();
      setAuthData("dropbox", data);
      const filename = window.sessionStorage.getItem("auth.current_filename");
      if (filename) {
        window.sessionStorage.removeItem("auth.current_filename");
        navigate(`/notes/${filename}`);
      }
      else {
        navigate("/notes");
      }
    }
    auth();
  }, []);

  return null;
}
