import { EditorView, Decoration, ViewUpdate, ViewPlugin, DecorationSet } from '@codemirror/view';
import { syntaxTree } from "@codemirror/language"
import { RangeSetBuilder } from '@codemirror/state'


const codeDeco = Decoration.line({ class: "cm-code-block" })

function codeblocks(view: EditorView) {
  let builder = new RangeSetBuilder<Decoration>()

  for (let { from, to } of view.visibleRanges) {
    syntaxTree(view.state).iterate({
      from,
      to,
      enter: ({type, from, to}) => {
        if (type.name === "CodeText") {
          for (let pos = from; pos <= to;) {
            let line = view.state.doc.lineAt(pos)
            builder.add(line.from, line.from, codeDeco)
            pos = line.to + 1
          }
        }
      }
    });
  }
  return builder.finish()
}

export const codeblockPlugin = ViewPlugin.fromClass(
  class {
    decorations: DecorationSet;

    constructor(view: EditorView) {
      this.decorations = codeblocks(view);
    }

    update(update: ViewUpdate) {
      if (update.docChanged || update.viewportChanged) {
        this.decorations = codeblocks(update.view);
      }
    }
  },
  {
    decorations: (v) => v.decorations,
  }
);
