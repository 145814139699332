import { useEffect, useState } from 'react';

import { buildString } from '../buildString';
import { AppContainer } from '../components/AppContainer';
import { Footer } from '../components/Footer';
import { Header, HeaderTitle } from '../components/Header';
import { IconRaw } from '../components/Icon';
import { MainContainer } from '../components/MainContainer';
import { ProviderItem, ProvidersContainer, ProvidersHeader, ProvidersList } from '../components/ProvidersContainer';
import { useTitle } from '../hooks/useTitle';
import { ProviderInfo } from "../storage/interface";
import { getAuthProviders } from '../storage/storage';

export function Auth() {
  const [providers, setProviders] = useState<ProviderInfo[]>([]);
  useTitle("Authentication");

  useEffect(() => {
    const fetchProviders = async () => {
      setProviders(await getAuthProviders());
    };

    window.sessionStorage.removeItem("auth.current_filename");
    fetchProviders();
  }, []);

  return (
    <AppContainer>
      <Header>
        <img src={process.env.PUBLIC_URL + "/logo192.png"} alt="MD Notes Logo" width={48} height={48} />
        <HeaderTitle>Notes</HeaderTitle>
      </Header>
      <MainContainer>
        <ProvidersContainer>
          <ProvidersList>
            <ProvidersHeader>Storage Providers</ProvidersHeader>
            {providers.map((item) => <ProviderItem key={item.provider} href={item.link}><IconRaw icon={item.icon} /> {item.title}</ProviderItem>)}
          </ProvidersList>
        </ProvidersContainer>
      </MainContainer>
      <Footer title={buildString()}>
        &copy; Serhii Shvorob 🇺🇦, 2022
      </Footer>
    </AppContainer>
  );
}
