import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useTitle } from '../hooks/useTitle';
import { setAuthData } from '../storage/storage';

export function AuthDummy() {
  const navigate = useNavigate();
  useTitle("Dummy");

  useEffect(() => {
    setAuthData("dummy", {});
    navigate("/notes");
  }, []);

  return null;
}
