import { CompletionContext, CompletionResult } from '@codemirror/autocomplete'


const tagRe = /\B[#@][\w_:-]*/g;
const tagFullRe = /\B[#@][\w_:-]+/g;
const dateRe = /\B\/\/[\d-]*/g


export function completions(context: CompletionContext): CompletionResult | null {
  // Check date pattern
  let word = context.matchBefore(dateRe)
  if (word) {
    const today = new Date()

    const options = Array.from(Array(10).keys()).map((value) => {
      const detail = value === 0 ? "today" : value === 1 ? "tomorrow" : undefined
      let date = new Date()
      date.setDate(today.getDate() + value)
      const dateStr = date.toISOString().slice(0, 10) // Strip time part

      return {
        label: date.toDateString(),
        apply: dateStr,
        detail
      }
    })

    return {
      from: word.from + 2,
      to: word.to,
      filter: false,
      options
    }
  }

  // Process tag/mention completion
  word = context.matchBefore(tagRe)
  if (!word) {
    return null;
  }
  if (word.from === word.to && !context.explicit) {
    return null;
  }
  
  const text = context.state.doc.sliceString(0);
  const matches = text.match(tagFullRe);
  if ((!matches) || (matches.length === 0)) {
    return null;
  }

  const currentWordIndex = matches.indexOf(word.text)
  // Remove current word under the cursor from matches
  let tags: string[] = Array.from(new Set(matches.filter((value, index) => (index !== currentWordIndex))));

  let to = word.to;
  let filter = true;

  // Getting trailing part of the tag
  if (context.explicit) {
    filter = false;
    let tagText = word.text;
    // filter tags or mentions, based on what autocomplition was triggred for
    tags = tags.filter(item => item.startsWith(tagText.slice(0, 1)));
    const textAfter = context.state.doc.sliceString(word.to, Math.min(word.to + 64, context.state.doc.length));
    const match = /^[\w:_-]+/.exec(textAfter);
    if (match) {
      to = to + match[0].length;
      tagText = tagText + match[0];
    }
    if (tagText.includes(":")) {
      const [group] = tagText.split(":", 1);
      tags = tags.filter(item => item.startsWith(group + ":"));
    }
  }
  
  return {
    from: word.from,
    to,
    filter,
    options: tags.map(item => ({label: item, type: "text"}))
  }
}
