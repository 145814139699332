import { markdown, markdownLanguage } from '@codemirror/lang-markdown';
import { syntaxHighlighting } from "@codemirror/language";
import { languages } from '@codemirror/language-data';

import { completions } from './completion';
import { highlight, markdownExtensions } from './markdown';
import { checkboxPlugin } from './plugins/checkbox';
import { codeblockPlugin } from './plugins/codeblock';
import { datePlugin } from './plugins/date';
import { tagMentionPlugin } from './plugins/tag_mention';
import { urlPlugin } from './plugins/url';
import { sortItemsKeymap } from './sort';
import { theme } from './theme';

export { sortItems } from './sort';


export const extensions = [
    theme,
    syntaxHighlighting(highlight),
    markdownLanguage.data.of({
      autocomplete: completions
    }),
    checkboxPlugin,
    tagMentionPlugin,
    datePlugin,
    codeblockPlugin,
    urlPlugin,
    sortItemsKeymap,
    markdown({base: markdownLanguage, codeLanguages: languages, extensions: markdownExtensions}),
  ];
  