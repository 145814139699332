import { tags } from "@lezer/highlight"
import { MarkdownConfig, DelimiterType } from '@lezer/markdown'

import { customTags } from "./highlight"


const HighlightedDelim: DelimiterType = {resolve: "Highlighted", mark: "HighlightedMark"}

export const Highlighted: MarkdownConfig = {
  defineNodes: [{
    name: "Highlighted",
    style: {"Highlighted/...": customTags.highlighted}
  }, {
    name: "HighlightedMark",
    style: tags.processingInstruction
  }],
  parseInline: [{
    name: "Highlighted",
    parse(cx, next, pos) {
      if (next !== 61 /* '=' */ || cx.char(pos + 1) !== 61) return -1
      return cx.addDelimiter(HighlightedDelim, pos, pos + 2, true, true)
    },
    after: "Emphasis"
  }]
}
