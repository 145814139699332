import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import ReactModal from 'react-modal';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

const onSWUpdate = (registration: ServiceWorkerRegistration) => {
  let banner = document.getElementById("update-banner");
  let link = document.getElementById("update-link");
  if (banner && link) {
    link.onclick = (e) => {
      e.preventDefault();
      registration.waiting?.postMessage({ type: 'SKIP_WAITING' });
      banner!.style.visibility = "hidden";
      window.location.reload();
    }
    banner.style.visibility = "visible";
  }
};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({ onUpdate: onSWUpdate });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// It is important for users of screenreaders that other page content be hidden 
// (via the aria-hidden attribute) while the modal is open. To allow react-modal 
// to do this, you should call Modal.setAppElement with a query selector 
// identifying the root of your app.
ReactModal.setAppElement("#root");