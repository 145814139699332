import {tags, Tag} from "@lezer/highlight"
import {HighlightStyle} from "@codemirror/language"

export const customTags = {
  highlighted: Tag.define(),
  mention: Tag.define(),
  tag: Tag.define(),
};

export const highlight = HighlightStyle.define([
  { tag: tags.heading, textDecoration: "none", fontWeight: "bold" },
  { tag: tags.heading1, fontWeight: "bold", fontSize: "18pt" },
  { tag: tags.heading2, fontWeight: "bold", fontSize: "16pt" },
  { tag: tags.heading3, fontWeight: "bold", fontSize: "14pt" },
  { tag: tags.heading4, fontWeight: "bold", fontSize: "12pt" },
  { tag: tags.heading5, fontWeight: "bold" },
  { tag: tags.heading6, fontWeight: "bold" },
  { tag: tags.emphasis, fontStyle: "italic" },
  { tag: tags.strong, fontWeight: "bold" },
  { tag: tags.strikethrough, textDecoration: "line-through" },
  { tag: tags.keyword, color: "#708" },
  { tag: tags.url, textDecoration: "underline", color: "#00f" },
  { tag: tags.monospace, class: "cm-code-inline" },
  {
    tag: [
      tags.meta,
      tags.atom,
      tags.bool,
      tags.contentSeparator,
      tags.blockComment,
      tags.comment,
    ],
    color: "#999"
  },

  { tag: customTags.highlighted, backgroundColor: "#ff08"},
]);
