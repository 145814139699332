import { Storage, FileInfo, ProviderInfo, SaveCallback } from "./interface";

const firstContent = `# Varash Backlog

<!-- 
#s:new #s:open #s:done #s:canceled
#t:bug[style=bgred] #t:feature[style=bgcyan] #t:other[style=bgyellow]
@me[style=bold]
-->

#tag1 #tag2 #tag3 #tag4 #tag5 #tag6 #tag7 #tag8 #tag9 #tag0

## New (#s:new)

- #s:new #t:feature Implement LocalStorage
- #s:new #t:feature Check auth on window/tab focus
- #s:new #t:feature Spinner on files list loading
  - Sub task
  - Sub tas
- #s:new #t:feature Spinner on file loading @olena
- #s:new #t:feature Remove source maps from prod build @mykyta dd
- #s:new #t:feature Favicon #tag #tag2
- #s:new #t:feature Folders support

## In Progress (#s:open)


## Done (#s:done)

- #s:done #t:bug Wrong redirect for DummyStorage
- #s:done #t:feature Check auth on open

## Canceled (#s:canceled)


One \`monospaced\` line.

\`\`\`
Fenced
code
block
\`\`\`
`

const todoContent = `# TODO Example

## TODO (#TODO)

- [ ] Task 1
- [ ] Task 2
- [ ] Task 3
- [ ] Task 4

## Done (#DONE)

Links:

* <https://google.com>
* <https://bing.com>
`

interface FileItem extends FileInfo {
    content: string
}

type RecordsMapping = Record<string, FileItem>

const initialState: RecordsMapping = {
    "first.md": {
        path: "first.md",
        type: "file",
        content: firstContent,
    },
    "second.md": {
        path: "second.md",
        type: "file",
        content: todoContent
    },
    "folder": {
        path: "folder",
        type: "folder",
        content: ''
    },
    "folder/one.md": {
        path: "folder/one.md",
        type: "file",
        content: 'one'
    },
    "folder/two.md": {
        path: "folder/two.md",
        type: "file",
        content: 'two'
    },
    "folder/sub": {
        path: "folder/sub",
        type: "folder",
        content: ''
    },
    "folder/sub/one.md": {
        path: "folder/sub/one.md",
        type: "file",
        content: 'one'
    },
}

for (let index = 0; index < 30; index++) {
    const path = `item_${index.toString(10).padStart(2, "0")}.md`;
    initialState[path] = {
        path,
        type: "file",
        content: ""
    }
}


export default class DummyStorage implements Storage {

    private state: RecordsMapping = initialState

    saveCallback?: SaveCallback

    static async getProviderInfo(): Promise<ProviderInfo> {
        return {
            provider: "dummy",
            title: "Test Data",
            icon: "fa-solid fa-desktop",
            link: `${window.location.origin}/auth/dummy`
        }
    }

    constructor(authData: object) {
    }

    async getAuthUrl(): Promise<string> {
        return ""
    }

    async getUser(): Promise<string> {
        return "dummy"
    }

    async logout(): Promise<void> {
    }

    async list(): Promise<FileInfo[]> {
        return Object.values(this.state);
    }

    async create(path: string): Promise<void> {
        // TODO: Check existance
        this.state[path] = {path, type: "file", content: ""}
    }

    async createFolder(path: string): Promise<void> {
        this.state[path] = {path, type: "folder", content: ""}
    }

    async delete(path: string): Promise<void> {
        delete this.state[path];
        
        // Delete sub items
        for (const item of Object.values(this.state)) {
            if (item.path.startsWith(path + "/"))
                delete this.state[item.path]
        }
    }

    async getFileContent(path: string): Promise<string> {
        return this.state[path].content;
    }

    async saveFileContent(path: string, content: string): Promise<void> {
        this.state[path].content = content;
        if (this.saveCallback) this.saveCallback(path)
    }

    flush(): boolean {
        return false
    }
}
