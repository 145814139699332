import { MarkdownConfig } from '@lezer/markdown'


export const DateHighlighter: MarkdownConfig = {
  defineNodes: ["Date"],
  parseInline: [{
    name: "Date",
    parse(cx, next, pos) {
      let match: RegExpExecArray | null
      if (next !== 47 /* '/' */ || cx.char(pos + 1) !== 47 || !(match = /^\d{4}-\d{2}-\d{2}/.exec(cx.slice(pos + 2, cx.end)))) return -1
      return cx.addElement(cx.elt("Date", pos, pos + 2 + match[0].length))
    }
  }]
}
